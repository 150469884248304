<template>
  <div class="ignore h-home-box" id="home">
    <header class="h-header-box flex-center">
      <section class="h-header-wh flex-vcenter jc-between">
        <div class="logo"></div>
        <div class="login flex-center">
          <a target="_blank" href="https://vms.vooedit.com"
            ><div class="createplugin">{{ curlang.sign }}</div></a
          >
        </div>
      </section>
    </header>
    <div class="h-video-box" :style="{ height: oneHeight + 'px' }">
      <video preload="auto" loop autoplay muted="muted" poster="https://biu-hk.dwstatic.com/seas/20211108/726f97af55276a73d764a2a5f7592fe5.jpg?w=1920&h=1080">
        <source
          src="https://biu-hk.dwstatic.com/vfly/20211012/b91d1dca050e7b31617543e139da276b.mp4"
          type="video/mp4"
        />
      </video>
      <div class="h-video-info flex-col flex-center">
        <div>
          <h4>{{ curlang?.one?.title || "" }}</h4>
          <p v-html="curlang?.one?.decs"></p>
        </div>
      </div>
      <div class="h-video-pos">
        <span :class="isEN ? '' :'cn'">
          {{ curlang?.one?.more || "" }}
          <i></i>
        </span>
      </div>
    </div>
    <div
      class="h-edit-box h-com-box flex-center"
      :style="{ height: height + 'px' }"
    >
      <div class="h-edit-content flex">
        <img class="h-edit-img" src="https://biu-hk.dwstatic.com/seas/20211108/fb27fc742da635a43989fab812ddec43.png?w=826&h=635" />
        <div class="flex-col flex-hcenter">
          <h4 v-html="curlang?.two?.title"></h4>
          <p v-html="curlang?.two?.decs"></p>
          <div class="download-btns flex wrap">
            <a
              class="btnWrap"
              href="https://apps.apple.com/app/id1573214536"
              target="_blank"
              ><img class="btn" src="./img/download/icon-apply.png"
            /></a>
            <a
              class="btnWrap"
              href="https://play.google.com/store/apps/details?id=com.vstyle.voo"
              target="_blank"
              ><img class="btn" src="./img/download/icon-google.png"
            /></a>
          </div>
          <div class="download-btns flex wrap">
            <a
              class="btnWrap"
              href="https://biu-hk.dwstatic.com/sky/20211227/VOO_1_3_0_official.dmg"
              download
              ><img class="btn" src="./img/download/icon-mac.png"
            /></a>
            <a
              class="btnWrap"
              href="https://biu-cn.dwstatic.com/zbshenqi/20220121/VOO_1_5_0_official_x64.exe"
              download
              ><img class="btn" src="./img/download/icon-ws.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-ai-box h-com-box flex-col flex-center"
      :style="{ height: height + 'px' }"
    >
      <div class="h-title-com">
        <h4 v-html="curlang?.three?.title"></h4>
        <p v-html="curlang?.three?.decs"></p>
      </div>
      <div class="h-ai-swiper">
        <div class="swiper-con swiper-01">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in videoUrl"
              :key="index"
            >
              <video class="load-lazy" preload="auto" loop muted="muted" :data-url="item.url" :poster="item.poster">
              </video>
            </div>
          </div>
        </div>
        <div class="swiper-box">
          <div class="swiper-con swiper-02">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in videoUrl"
                :key="index"
              >
                <div class="flex-center">
                  <span>{{ isEN ? item.name : item.cnName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-quick-box h-com-box load-lazy"
      id="sticky"
      :style="{ height: height + 'px' }"
    >
      <div class="h-quick-sticky">
        <div class="h-title-com">
          <h4 v-html="curlang?.fore?.title"></h4>
          <p v-html="curlang?.fore?.decs"></p>
        </div>
        <div class="h-quick-content">
          <div
            v-for="(item, index) in wpList"
            class="h-quick-img "
            :class="index == 0 ? 'one' : index == 1 ? 'two' : 'three'"
            :key="index"
          >
            <picture class="pic">
              <source class="wpImg" :data-src='isEN ? item.url : item.cnURL' type="image/webp" />
              <img :src="'./img/p-01.jpg'" alt="">
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div
      class="h-mt-box h-com-box flex-col flex-center"
      :style="{ height: height + 'px' }"
    >
      <div class="h-title-com">
        <h4 v-html="curlang?.five?.title"></h4>
        <p v-html="curlang?.five?.decs"></p>
      </div>
      <div class="h-mt-content flex">
        <div class="h-mt-icons flex-col flex-hcenter">
          <div>
            <div
              class="h-icon-item"
              v-for="(item, index) in imgUrl"
              :key="index"
              v-on:mouseover="curImgUrl = item.img"
            >
              <h5 class="flex-vcenter">
                <i :class="item.icon"></i>{{ isEN ? item.title : item.cnTitle }}
              </h5>
              <span>{{ isEN ? item.decs : item.cnDecs }}</span>
            </div>
          </div>
        </div>
        <div class="h-mt-img flex-g-1">
          <img :src="curImgUrl" />
        </div>
        <div class="h-mt-icons flex-col flex-hcenter">
          <div>
            <div
              class="h-icon-item"
              v-for="(item, index) in imgUrlOne"
              :key="index"
              v-on:mouseover="curImgUrl = item.img"
            >
              <h5 class="flex-vcenter">
                <i :class="item.icon"></i>{{ isEN ? item.title : item.cnTitle }}
              </h5>
              <span>{{ isEN ? item.decs : item.cnDecs }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="h-footer-header flex jc-between">
        <div class="logo">
          <img src="./img/logo-01.png" />
        </div>
        <div class="h-other">
          <a target="_blank" href="https://forms.gle/5FNnWgSjg1pQiuZU8"
            ><img src="./img/icon-feed.png"
          /></a>
          <a
            class="icon-content"
            href="https://www.facebook.com/VOO-103217338414216"
            target="_blank"
            ><img src="./img/icon-face.png"
          /></a>
        </div>
      </div>
      <div class="canpay">
        <p>
          {{ ipcObj.cap }} | <a href="http://agreement.vooedit.com/VOO-PrivacyPolicy.htm" target="_blank">隐私协议</a> | <a href="http://agreement.vooedit.com/VOO-Agreement.htm" target="_blank">用户协议</a>
          <br />
          Contact: <a href="mailto: luluboxnew@gmail.com">vooteam66@gmail.com</a
          ><br />
          <a :href="ipcObj.url">{{ ipcObj.ipc }}</a>
        </p>
        <p >
          <a target="_blank" :href="ipcObj.wUrl" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="./img/icon-w.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">{{ipcObj.wip}}</p></a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>

const langObj = {
  en: {
    sign: "Sign In to the Console",
    one: {
      title: "EXPLORE FUTURE EDITING",
      decs: "More simpler, more efficient, and more effective<br />full-platform editing software",
      more: "Slide to see more",
    },
    two: {
      title: "Edit Anytime,<br/> Anywhere",
      decs: "Mobile phones,Tablets,and PCs are supported,<br/> and wherever you go, your creation will no longer be limited",
    },
    three: {
      title: "Rich AI Effects",
      decs: "VOO combined with AI, can easily achieve anime special effects,body strokes,and facial stickers,to make your work unique",
    },
    fore: {
      title: "Advanced Editing，Not Complicated",
      decs: "VOO uses a simple authoring panel,curve shifting, AI,keyframe extraction, and 4K editing to make editing at your fingertips.",
    },
    five: {
      title: "Rich Materials, Unlock Creativity",
      decs: "The rich materials in VOO,music, special effects,transitions,<br/> stickers,text,filters, make the work more attractive!",
    },
  },
  cn: {
    sign: "登录管理后台",
    one: {
      title: "“剪”创未来",
      decs: "更简单，更高效，效果更丰富的全平台剪辑软件",
      more: "滑动以查看更多",
    },
    two: {
      title: "<span class='bold'>随时随地</span> 剪你想剪",
      decs: "手机、平板、电脑三端互通<br />到哪里剪到哪里，创作从此不受限制",
    },
    three: {
      title: "<span class='bold'>AI特效</span> 创作从来不止一面",
      decs: "VOO结合AI技术，让你轻松实现动漫特效、身体描边、面部贴纸，让你的作品与众不同",
    },
    fore: {
      title: "<span class='bold'>高级的剪辑</span> 不必那么复杂",
      decs: "VOO采用直观易用的创作面板、曲线变速、AI技术、关键帧提取、4K编辑<br/>让专业剪辑变得触手可得，让每个人都能感受到创作的乐趣",
    },
    five: {
      title: "<span class='bold'>精彩素材</span> 解锁无限创意",
      decs: "VOO提供各种各样的素材，背景音乐、特效、转场、贴纸、文字、滤镜<br/> 让你的作品表现力十足！",
    },
  },
};
function getLanguage() {
  const language = navigator.language
    ? navigator.language
    : navigator.browserLanguage;
  return language;
}
var getUrlParams = function (name) {
  var search = window.location.search.match(/\?.*(?=\b|#)/);
  search && (search = search[0].replace(/^\?/, ""));
  if (!search) return name ? "" : {};
  var queries = {},
    params = search.split("&");

  for (var i in params) {
    var param = params[i].split("=");
    queries[param[0]] = param[1] ? decodeURIComponent(param[1]) : "";
  }

  return name ? queries[name] : queries;
};
function initLang() {
  let lang = getLanguage() || "en";
  const l = getUrlParams("lang").toLocaleLowerCase();
  if (l) {
    return Object.is(l, "en");
  } else {
    return !Object.is(lang, "zh-CN");
  }
}

export default {
  components: {},
  data() {
    return {
      oneHeight: window.innerHeight,
      height: window.innerHeight >= 800 ? window.innerHeight : 800,
      curImgUrl: "https://biu-hk.dwstatic.com/seas/20211108/2ffca0a149200975d0137725d2e77b9f.png?w=505&h=638",
      isEN: initLang(),
      observer: null,
      ipcObj: {
        url: 'https://beian.miit.gov.cn/',
        ipc: '粤ICP备09075143号',
        cap: this.isEN ? `GOKOO TECHNOLOGY PTE.LTD Copyright© VOO Team${ new Date().getFullYear()
 }, All Rights Reserved` : '广州华多网络科技有限公司 | 联系电话：020-82120431',
        wip: '粤公网安备 44011302003509号',
        wUrl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302003509'
      },
      wpList: [
        {
          url: "https://biu-hk.dwstatic.com/seas/20211014/0e7bf53be2b861e33f8395319708c6f4.webp?w=1000&h=600",
          cnURL:
            "https://biu-hk.dwstatic.com/seas/20211014/9c335620e190376a31e6c9c95ebc273d.webp?w=1000&h=600",
        },
        {
          url: "https://biu-hk.dwstatic.com/seas/20211014/4f81a559dc6c6bcab298922f9dd23257.webp?w=1000&h=600",
          cnURL:
            "https://biu-hk.dwstatic.com/seas/20211014/c000cb3ac5cdedd67fd92612310120bb.webp?w=1000&h=600",
        },
        {
          url: "https://biu-hk.dwstatic.com/seas/20211014/e09088947bef9befe3ef75b427619a86.webp?w=1000&h=600",
          cnURL:
            "https://biu-hk.dwstatic.com/seas/20211014/bec6e55953f20be3f0f729c79d223cd6.webp?w=1000&h=600",
        },
      ],
      videoUrl: [
        {
          name: "Anime",
          cnName: "动漫特效",
          url: "https://biu-hk.dwstatic.com/seas/20211014/9ac8b14e3e082b2f4828a718d579909a.mp4",
          poster: "https://biu-hk.dwstatic.com/seas/20211108/298fc0b853d9ead5032d99c86512a3a0.jpg?w=1000&h=550",
        },
        {
          name: "Stroke",
          cnName: "身体描边",
          url: "https://biu-hk.dwstatic.com/vfly/20211012/fd5223b507e3353cdd377ef3860e8b4b.mp4",
          poster: "https://biu-hk.dwstatic.com/seas/20211108/b87cc7dddc5fdc019b01858cbaeb435d.jpg?w=1000&h=550",
        },
        {
          name: "Sticker",
          cnName: "脸部贴纸",
          url: "https://biu-hk.dwstatic.com/vfly/20211012/c2af965dfc9c55f26054ffeec1cd6d42.mp4",
          poster: 'https://biu-hk.dwstatic.com/seas/20211108/500df9e6555f4b1ac8cb9e51749c3ee5.jpg?w=1000&h=550',
        },
      ],
      imgUrl: [
        {
          icon: "video",
          title: "Video",
          decs: "Easily find the material of all popular videos",
          cnTitle: "视频",
          cnDecs: "热门视频素材一网打尽，找素材不再费劲",
          img: "https://biu-hk.dwstatic.com/seas/20211108/2ffca0a149200975d0137725d2e77b9f.png?w=505&h=638",
        },
        {
          icon: "sticker",
          title: "Sticker",
          decs: "A variety of fun stickers to make the video more interesting",
          cnTitle: "贴纸",
          cnDecs: "各种趣味贴纸，让视频更有趣",
          img: "https://biu-hk.dwstatic.com/seas/20211108/744c370b529db21a220c438b7efbf9c7.png?w=505&h=638",
        },
        {
          icon: "transition",
          title: "Transition",
          decs: "Cool transition effect, make the video transition more natural",
          cnTitle: "转场",
          cnDecs: "炫酷的转场特效，让你的视频过渡摆脱生硬",
          img: "https://biu-hk.dwstatic.com/seas/20211108/d88b4e70065e712c5a3536d909994fdb.png?w=505&h=638",
        },
      ],
      imgUrlOne: [
        {
          icon: "audio",
          title: "Audio",
          decs: "Trend hot list + Thousands of sound effects, let the video always lead the trend!",
          cnTitle: "音频",
          cnDecs: "潮流热单+千种音效，让视频时刻引领潮流",
          img: "https://biu-hk.dwstatic.com/seas/20211108/023387e671ac8ce4d149d2b9c3e4c5bc.png?w=505&h=638",
        },
        {
          icon: "special",
          title: "Special Effects",
          decs: "Unique AI effects and excellent picture experience",
          cnTitle: "特效",
          cnDecs: "独特AI特效，提供前所未有的画面体验",
          img: "https://biu-hk.dwstatic.com/seas/20211108/4d9ab85c8839f45a14c13f1ee08c636f.png?w=505&h=638",
        },
        {
          icon: "filter",
          title: "Filter",
          decs: "A wealth of fashion filters to light up your picture",
          cnTitle: "滤镜",
          cnDecs: "多种时尚滤镜，点亮你的画面",
          img: "https://biu-hk.dwstatic.com/seas/20211108/4f95380827d7d1b29755d9621132efc5.png?w=505&h=638",
        },
      ],
      curlang: {},
    };
  },
  computed: {},
  methods: {
    initLang() {
      this.curlang = this.isEN ? langObj.en : langObj.cn;
    },
    initScrollObserver() {
      const wpList =document.querySelectorAll('.wpImg')
      const observers = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            if(entry.target.dataset.url) {
              entry.target.src = entry.target.dataset.url
            } else {
              wpList.forEach((e) => {
                e.srcset = e.dataset.src
              })
            }
            observers.unobserve(entry.target);
          }
        })
      }, {
          rootMargin: '0px 0px 300px 0px',
          threshold: [0.1, 0.95]
      })
      const listNode = document.querySelectorAll('.load-lazy')
      listNode.forEach((e) => {
        observers.observe(e)
      })
    },
  },
  beforeCreate() {},
  async mounted() {
    if(!location.hostname.includes('vooedit.com')) {
      this.ipcObj = {
        url: 'https://beian.miit.gov.cn/',
        ipc: '粤ICP备15039775号',
        cap: this.isEN ? `GOKOO TECHNOLOGY PTE.LTD Copyright© VOO Team${ new Date().getFullYear()
 }, All Rights Reserved` : '广州欢聚时代信息科技有限公司 | 联系电话：020-82120431',
        wip: '粤公网安备 44011302003510号',
        wUrl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302003510'
      }
    }
    this.initScrollObserver();
    this.initLang();
    const chidSwiper = new window.Swiper(".swiper-02", {
      slidesPerView: 1,
      loop: true,
      loopedSlides: 5,
      speed: 300,
      freeMode: true,
      allowTouchMove: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      on: {},
    });
    new window.Swiper(".swiper-01", {
      spaceBetween: 40,
      slidesPerView: 1,
      loop: true,
      loopedSlides: 5,
      thumbs: {
        swiper: chidSwiper,
      },
      on: {
        slideChange: function () {
          this.slides.forEach((e) => {
            e.children[0] && e.children[0].pause();
          });
          const videoRef = this.slides[this.activeIndex].children[0];
          if (videoRef) {
            videoRef.play();
          }
        },
      },
    });
  },
};
</script>

<style lang="less">
.ignore {
  width: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &::-webkit-media-controls {
      position: relative;
      z-index: -1;
      display: none !important;
    }
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
  }
  &.h-home-box {
    position: relative;
    background: #090912;
  }
  .h-header-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background: rgba(#060608, 0.5);
    z-index: 10;
    .h-header-wh {
      min-width: 1200px;
      height: 100%;
      .logo {
        width: 161px;
        height: 25px;
        background: url("./img/icon-logo.png") no-repeat center/100%;
      }
      .login {
        height: 36px;
        background: linear-gradient(
          86deg,
          #ffd2a6 0%,
          #0fc4f5 0%,
          #1893ff 100%
        );
        border-radius: 6px;
        .createplugin{
          padding: 0 20px;
        }
        a {
          text-decoration: none;
          color: #002c76;
          font-size: 18px;
        }
      }
    }
  }
  .h-video-box {
    width: 100%;
    height: 100%;
    position: relative;

    .h-video-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      h4{
        letter-spacing: 2.5px;
        font-weight: 600;
      }
      h4,
      p {
        padding: 0;
        margin: 0 0 14px;
        font-size: 80px;
        font-family: Myriad Variable Concept;
        color: #fff;
        text-align: center;
      }
      p {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1.5px;
      }
    }
    .h-video-pos {
      position: absolute;
      left: 0;
      bottom: 86px;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        font-size: 28px;
        font-weight: 300;
        color: #ebebeb;
        &.cn{
          font-size: 18px;
        }
        i {
          display: block;
          margin: 20px auto 0;
          width: 20px;
          height: 20px;
          background: url("./img/icon-more.png") no-repeat center bottom/ 20px;
          animation: moreAnimate 1s linear infinite forwards;
        }
      }
    }
  }
  .h-com-box {
    max-width: 1400px;
    // min-width: 1280px;
    min-height: 650px !important;
    margin: 0 auto;
    .h-title-com {
      h4 {
        font-size: 49px;
        font-weight: 600;
        color: #fff;
        line-height: 56px;
        margin-bottom: 18px;
        text-align: center;
        span {
          font-size: 50px;
          font-weight: 100;
          color: #ffffff;
          background: linear-gradient(0deg, #01edde 0%, #04a7dd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &.bold {
            font-weight: 600;
          }
        }
      }
      p {
        font-size: 16px;
        color: #d2d2d2;
        line-height: 1.5;
        font-weight: 300;
        text-align: center;
      }
    }
  }
  .h-edit-box {
    .h-edit-content {
      min-height: 615px;
      .h-edit-img {
        margin-left: -152px;
        width: 800px;
        height: 615px;
      }

      h4 {
        font-size: 49px;
        font-weight: 600;
        color: #fff;
        line-height: 56px;
        margin-bottom: 18px;
        span {
          font-size: 50px;
          font-weight: 600;
          color: #ffffff;
          background: linear-gradient(0deg, #01edde 0%, #04a7dd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        font-size: 16px;
        color: #d2d2d2;
        line-height: 1.5;
        font-weight: 300;
        margin-bottom: 40px;
      }
      .download-btns {
        margin-bottom: 20px;
        a {
          overflow: hidden;
          margin-right: 18px;
          border-radius: 4px;
          width: 217px;
          height: 65px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .h-ai-box {
    overflow: hidden;
    max-width: 100% !important;
    .h-title-com {
      margin-bottom: 74px;
    }
    .swiper-con {
      span {
        color: #fff;
      }
    }
    .swiper-01 {
      height: 550px;
      width: 1000px;
      margin-bottom: 35px;

      .swiper-slide {
        transition: transform 0.05s linear;
        transform: scale(0.5);
        &.swiper-slide-prev {
          transform: translateX(65px) scale(0.85);
          opacity: 0.5;
        }
        &.swiper-slide-next {
          transform: translateX(-65px) scale(0.85);
          opacity: 0.5;
        }
        &.swiper-slide-active {
          transform: scale(1);
        }
      }
    }
    .swiper-box {
      width: 588px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      .swiper-02 {
        width: 196px;
        height: 40px;
        margin: 0 auto;
        .swiper-slide{
          cursor: pointer;
          span{
            font-size: 26px;
            color: #fff;
            font-weight: 300;
          }
        }
        .swiper-slide-active{
          span {
            position: relative;
            font-weight: 400;
            &::before {
              position: absolute;
              bottom: -2px;
              left: 0;
              width: 100%;
              z-index: 10;
              height: 1px;
              background: linear-gradient(86deg, #FFD2A6 0%, #0FC4F5 0%, #1893FF 100%);
              content: "";
            }
          }
        }
        .swiper-slide-prev,
        .swiper-slide-next {
          span {
            position: relative;
            &::before {
              position: absolute;
              top: 0;
              width: 100%;
              z-index: 10;
              height: 100%;
              content: "";
            }
          }
        }
        .swiper-slide-prev {
          span {
            &::before {
              background: linear-gradient(
                to right,
                rgba(#090912, 0.8) 50%,
                transparent 100%
              );
              left: 0;
            }
          }
        }
        .swiper-slide-next {
          span {
            &::before {
              background: linear-gradient(
                to left,
                rgba(#090912, 0.9) 50%,
                transparent 100%
              );
              right: 0;
            }
          }
        }
      }
    }
  }
  .h-quick-box {
    width: 100%;
    height: 980px !important;
    overflow: hidden;
    .h-quick-sticky {
      // width: 100vw;
      // height: 100vh;
      // position: sticky;
      // position: -webkit-sticky;
      // z-index: 100;
      // top: 0;
      // overflow: hidden;
    }
    .h-title-com {
      margin-top: 75px;
      margin-bottom: 75px;
    }
    .h-quick-content {
      width: 1000px;
      height: 600px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      will-change: animation;
      .h-quick-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 40px;
        z-index: 1;
        opacity: 0;
        z-index: 10;
        transform: translateY(600px);
        .pic,source{
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &.one {
          opacity: 1;
          animation: oneAnimate 12s linear infinite forwards;
        }
        &.two {
          animation: twoAnimate 12s linear infinite forwards;
        }
        &.three {
          animation: thAnimate 12s linear infinite forwards;
        }
      }
    }
  }
  .h-mt-box {
    max-width: 1124px;
    margin-bottom: 80px;
    .h-title-com {
      margin-bottom: 78px;
    }
    .h-mt-img {
      text-align: center;
      img {
        max-width: 505px;
        height: auto;
      }
    }
    .h-mt-content {
      width: 100%;
      margin: 0 auto;
    }
    .h-mt-icons {
      width: 300px;
      flex-basis: 300px;
      .h-icon-item {
        width: 100%;
        height: 150px;
        padding: 20px 25px;
        box-sizing: border-box;
        margin-bottom: 25px;
        transition: all 0.15s linear;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        h5 {
          font-size: 28px;
          line-height: 1.4;
          margin-bottom: 20px;
          font-weight: 500;
          width: 100%;
          color: #fff;
          i {
            width: 29px;
            height: 29px;
            margin-right: 15px;
            display: inline-block;
            &.video {
              background: url("./img/icon-02.png") no-repeat center/100%;
            }
            &.sticker {
              background: url("./img/icon-04.png") no-repeat center/100%;
            }
            &.transition {
              background: url("./img/icon-06.png") no-repeat center/100%;
            }
            &.audio {
              background: url("./img/icon-05.png") no-repeat center/100%;
            }
            &.special {
              background: url("./img/icon-03.png") no-repeat center/100%;
            }
            &.filter {
              background: url("./img/icon-01.png") no-repeat center/100%;
            }
          }
        }
        span {
          font-size: 16px;
          color: #eaeaea;
          font-weight: 300;
        }
        &:hover {
          background: rgba(19, 28, 41, 0);
          box-shadow: 0px 3px 10px 0px rgba(38, 202, 237, 0.41);
          // opacity: 0.7;
          border-radius: 5px;
          h5 {
            background: linear-gradient(0deg, #01edde 0%, #04a7dd 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            i {
              width: 29px;
              height: 29px;
              margin-right: 15px;
              display: inline-block;
              &.video {
                background: url("./img/icon-action-02.png") no-repeat
                  center/100%;
              }
              &.sticker {
                background: url("./img/icon-action-04.png") no-repeat
                  center/100%;
              }
              &.transition {
                background: url("./img/icon-action-06.png") no-repeat
                  center/100%;
              }
              &.audio {
                background: url("./img/icon-action-05.png") no-repeat
                  center/100%;
              }
              &.special {
                background: url("./img/icon-action-03.png") no-repeat
                  center/100%;
              }
              &.filter {
                background: url("./img/icon-action-01.png") no-repeat
                  center/100%;
              }
            }
          }
        }
      }
    }
  }
  footer {
    width: 1200px;
    margin: 0 auto;
    padding: 33px 0 55px;
    .h-footer-header {
      padding-bottom: 26px;
      border-bottom: 1px solid rgba(#fff, 0.2);
      margin-bottom: 34px;
      .logo {
        width: 63px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .h-other {
        img {
          width: 102px;
          height: 21px;
          margin-right: 24px;
        }
      }
    }
    .canpay {
      p,
      a {
        font-size: 16px;
        color: rgba(#ffff, 0.7);
        line-height: 1.6;
        font-weight: 300;
        text-align: center;
      }
      a {
        text-decoration: none;
      }
    }
  }

  @keyframes moreAnimate {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
  }

  @keyframes oneAnimate {
    0%,
    30% {
      z-index: 11;
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
    33% {
      opacity: 0.9;
      z-index: 10;
      transform: translate(0, -40px) scale(0.9);
    }
    33.33%,
    96% {
      opacity: 0;
      z-index: 10;
      transform: translate(0, 600px) scale(0.9);
    }
    96% {
      opacity: 1;
      z-index: 10;
      transform: translate(0, 600px) scale(1);
    }
    100% {
      z-index: 10;
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }
  @keyframes twoAnimate {
    0%,
    100% {
      opacity: 0;
      z-index: 10;
      transform: translate(0, 600px) scale(0.9);
    }
    30% {
      z-index: 10;
      opacity: 0.9;
      transform: translate(0, 600px) scale(1);
    }
    33%,
    63% {
      z-index: 11;
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
    66.66% {
      opacity: 0;
      z-index: 10;
      transform: translate(0, -40px) scale(0.9);
    }
  }
  @keyframes thAnimate {
    0%,
    100% {
      opacity: 0;
      z-index: 10;
      transform: translate(0, 600px) scale(0.9);
    }
    63% {
      z-index: 10;
      opacity: 0.9;
      transform: translate(0, 600px) scale(1);
    }
    66%,
    96% {
      opacity: 1;
      z-index: 11;
      transform: translate(0, 0) scale(1);
    }
    99.99% {
      opacity: 0;
      z-index: 10;
      transform: translate(0, -40px) scale(0.9);
    }
  }
}
</style>
